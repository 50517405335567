import { computed, onMounted, onServerPrefetch, ref, watch } from 'vue';
import { useAppStore, useLanguageStore, useRoutingStore } from '@/helpers/composables/useStores';
import { shiftDateWithTimezone } from '@/helpers/date-helper';
import { useMounted } from '@vueuse/core';

// Will be set to true after first navigation to ensure we stop using the persisted ssrTodaysDate
const didUpdateServerDate = ref(false);

/**
 * Composable to persist today's date across SSR and client side.
 *
 * - During SSR, it sets the date using the server's timezone and stores it in `ssrTodaysDate`.
 * - On the client, it updates to the correct date based on the timezone.
 * - Prevents hydration mismatches by using the precomputed SSR date before mounting.
 *
 * @returns Today's date shifted by the timezone.
 */
export function usePersistedTodaysDate() {
	const { ssrTodaysDate, setSSRTodaysDate } = useAppStore();
	const { localeObject } = useLanguageStore();
	const isMounted = useMounted();
	const { activeRoute } = useRoutingStore();

	onServerPrefetch(() => {
		setSSRTodaysDate(shiftDateWithTimezone(localeObject.value.timezone));
	});

	onMounted(() => {
		if (didUpdateServerDate.value) return;

		const unwatch = watch(activeRoute, () => {
			didUpdateServerDate.value = true;
			unwatch();
		});
	});

	const todayDate = computed(() => {
		if (
			ssrTodaysDate.value &&
			// first if the component not yet mounted we keep returning ssrTodaysDate.
			!isMounted.value &&
			// if the user did navigate to another page we return the clients date
			!didUpdateServerDate.value
		) {
			return new Date(ssrTodaysDate.value);
		}

		return shiftDateWithTimezone(localeObject.value.timezone);
	});

	return {
		todayDate,
	};
}
