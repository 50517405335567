import type { ApolloError } from 'apollo-client';
import type { MaybeRefOrGetter } from '@vueuse/core';
import type { FromRefOrGetter } from '@/helpers/types';
import type {
	GetGlobalNewTitlesQuery,
	GetGlobalNewTitlesQueryVariables,
} from '@/pages/graphql/queries/GetGlobalNewTitles.query';
import type {
	GetPopularTitlesQuery,
	GetPopularTitlesQueryVariables,
} from '@/pages/graphql/queries/GetPopularTitles.query';

import { NewPageType, ObjectType, PopularTitlesSorting } from '@/@types/graphql-types';

import { GetGlobalNewTitlesDocument } from '@/pages/graphql/queries/GetGlobalNewTitles.query';
import { GetPopularTitlesDocument } from '@/pages/graphql/queries/GetPopularTitles.query';

import { computed } from 'vue';
import { toValue } from '@vueuse/core';
import { useQuery } from '@/helpers/composables/useApollo';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

export type TitleType = 'TOP_10' | 'UPCOMING' | 'NEW' | 'POPULAR';

export type GlobalModuleName =
	| 'MAIN_TOP_10_MOVIES'
	| 'MAIN_TOP_10_SHOWS'
	| 'MAIN_NEW_MOVIES'
	| 'MAIN_NEW_SHOWS'
	| 'MAIN_POPULAR_MOVIES'
	| 'MAIN_POPULAR_SHOWS'
	| 'MAIN_TOP_SPORTS'
	| 'MAIN_LARGE_SPOTLIGHT'
	| 'MAIN_SMALL_SPOTLIGHT'
	| 'MAIN_TOP_PROVIDER';

interface UseGlobalTitlesOptions {
	country: MaybeRefOrGetter<string>;
	language: MaybeRefOrGetter<string>;
	objectType: MaybeRefOrGetter<ObjectType>;
	type: MaybeRefOrGetter<TitleType>;
}

type GlobalTitles<OptionTitleType extends TitleType> = OptionTitleType extends 'NEW'
	? NonNullable<GetGlobalNewTitlesQuery['newTitles']['edges']>
	: NonNullable<GetPopularTitlesQuery['popularTitles']['edges']>;

export function useGlobalTitles<T extends UseGlobalTitlesOptions = UseGlobalTitlesOptions>(options: T) {
	const { country, language, objectType, type } = options;

	const newTitlesQuery = useQuery<GetGlobalNewTitlesQuery, GetGlobalNewTitlesQueryVariables>(
		GetGlobalNewTitlesDocument,
		() => ({
			country: toValue(country),
			language: toValue(language),
			first: 40,
			filter: {
				objectTypes: [toValue(objectType) === ObjectType.Show ? ObjectType.ShowSeason : ObjectType.Movie],
			},
			date: '2022-11-22',
			pageType: NewPageType.New,
		}),
		() => ({ errorPolicy: 'all', fetchPolicy: 'cache-first', enabled: toValue(type) === 'NEW' })
	);

	const newTitlesData = computed(() => newTitlesQuery.result.value?.newTitles.edges ?? []);
	newTitlesQuery.onError(onError);

	const popularTitlesQuery = useQuery<GetPopularTitlesQuery, GetPopularTitlesQueryVariables>(
		GetPopularTitlesDocument,
		() => {
			const typeValue = toValue(type);
			return {
				first: typeValue === 'POPULAR' ? 40 : 10,
				popularTitlesFilter: {
					objectTypes: [toValue(objectType)],
					excludeIrrelevantTitles: false,
				},
				watchNowFilter: {},
				popularTitlesSortBy:
					typeValue === 'POPULAR' ? PopularTitlesSorting.Popular : PopularTitlesSorting.Trending,
				language: toValue(language),
				country: toValue(country),
			};
		},
		() => ({ errorPolicy: 'all', fetchPolicy: 'cache-first', enabled: toValue(type) !== 'NEW' })
	);

	const popularTitlesData = computed(() => popularTitlesQuery.result.value?.popularTitles.edges ?? []);
	popularTitlesQuery.onError(onError);

	function onError(error: ApolloError) {
		captureMessageForSentry(
			'[GraphQL Get Global New Title Data]:',
			{ error, where: 'Composable: useGlobalTitles' },
			'error'
		);
	}

	type IsNewTitleType = FromRefOrGetter<T['type']>;
	const titles = computed(
		() => (toValue(type) === 'NEW' ? newTitlesData.value : popularTitlesData.value) as GlobalTitles<IsNewTitleType>
	);

	return { titles };
}
