
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import PictureComp from '@/components/picture/PictureComp.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import BasicButton from '@/ui-components/BasicButton.vue';
import TitleDetailLink from '@/components/nav/links/TitleDetailLink.vue';

import { ObjectType, Platform } from '@/@types/graphql-types';
import { SnowplowContext, SnowplowModuleContext } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import {
	GetGlobalSmallSpotlightTitlesDocument,
	GetGlobalSmallSpotlightTitlesQuery,
	GetGlobalSmallSpotlightTitlesQueryVariables,
} from '@/pages/graphql/queries/GetGlobalSmallSpotlightTitles.query';
import { AssertByTypename } from '@/helpers/graphql-model-helper';
import { GlobalModuleName } from '@/helpers/composables/useGlobalTitles';
import { ImpressionTrackingEvents } from '@/enums/events';
import { defaultObservableOptions } from '@/mixins/types/impressionTracking';
import { usePersistedTodaysDate } from '@/helpers/composables/usePersistedTodaysDate';

type SmallSpotlightTitle = NonNullable<GetGlobalSmallSpotlightTitlesQuery['newTitles']['edges']>[number];

@Component({
	name: 'GlobalSmallSpotlight',
	components: {
		ProviderIcon,
		BasicButton,
		PictureComp,
		TitleDetailLink,
	},
	apollo: {
		globalNewSpotlightTitles: {
			query: GetGlobalSmallSpotlightTitlesDocument,
			variables(): GetGlobalSmallSpotlightTitlesQueryVariables {
				return this.queryVariables;
			},
			update(data) {
				return data.newTitles.edges;
			},
		},
	},
	setup() {
		const { todayDate } = usePersistedTodaysDate();
		return {
			todayDate,
		};
	},
})
export default class GlobalSmallSpotlight extends Vue {
	globalNewSpotlightTitles: SmallSpotlightTitle[] = [];

	@Prop() language: string;
	@Prop() country: string;
	@Prop({ required: true }) moduleName: GlobalModuleName;

	declare todayDate: Date;

	get queryVariables() {
		return {
			country: this.country,
			date: this.todayDate.toISOString().split('T')[0],
			first: 2,
			filter: { objectTypes: ObjectType.Show },
			language: this.language,
			platform: Platform.Web,
		};
	}

	get titlesToShow() {
		return this.globalNewSpotlightTitles.filter(title => title.node.__typename === 'Season') as {
			node: AssertByTypename<SmallSpotlightTitle['node'], 'Season'>;
		}[];
	}

	get moduleContext() {
		return new SnowplowModuleContext('', -1, this.moduleName, '');
	}

	getBackdrop(backdrops: { backdropUrl: string }[]) {
		return backdrops[0]?.backdropUrl || '';
	}

	getTitle(title: any) {
		return title.node.show;
	}

	getPackageId(title: any) {
		return this.getTitle(title).watchNowOffer?.package.packageId;
	}

	handleMoreInfoClick(title: any, contexts: SnowplowContext[] = []) {
		const titleContext = TrackingHelper.getTitleContextGraphql(
			title.objectId,
			title.objectType,
			title?.content?.seasonNumber
		);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'more_info_clicked',
				label: 'new_shows',
			},
			[...contexts, titleContext, this.moduleContext]
		);
	}

	get observeVisibilityOptions() {
		return {
			...defaultObservableOptions,
			callback: (isVisible: boolean) => {
				if (!isVisible) return;

				TrackingHelper.trackEvent(
					ImpressionTrackingEvents.MODULE,
					{
						action: 'impression',
						nonInteraction: true,
					},
					[this.moduleContext]
				);
			},
		};
	}
}
