
import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import { Component, Prop } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { namespace } from 'vuex-class';

import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import BasicButton from '@/ui-components/BasicButton.vue';

import { CollectionType } from '@/enums/collection-type';
import { OnboardingStatus } from '@/enums/onboarding-status';

import { calculateUrl } from '@/helpers/state-helper';
import { TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import { Package } from '@/@types/graphql-types';
import type { Genre, Subgenre } from '@/constants/types';
import { FilterName } from '@/stores/filter.store';
import { TasteSurveyType, UserSettings } from '@/stores/user.store';

const Constant = namespace('constant');
const Filter = namespace('filter');
const Routing = namespace('routing');
const User = namespace('user');

const DISCOVERY_INVITATION_EVENT_CATEGORY = 'discovery';

Vue.use(VueObserveVisibility);

@Component({
	name: 'CallToAction',
	components: {
		BasicButton,
		ProviderIcon,
	},
})
export default class CallToAction extends Vue {
	ctaShownOnce = false;

	@Prop({ type: Boolean, default: true }) showFeatureButton: boolean;
	@Prop({ type: Boolean, default: true }) showProviders: boolean;
	@Prop({ type: Boolean, default: true }) lazy: boolean;

	@Constant.Getter genresByShortName: Record<string, Genre>;
	@Constant.Getter subgenresList: Subgenre[];
	@Constant.Getter providersByShortName: Record<string, Package>;
	@Constant.Getter allProviders: (filterByProviders: boolean) => Package[];
	@Constant.Getter preselectedProviders: string[];

	@Filter.Action setFilterValue: (payload: {
		filterName: FilterName;
		filterValue: any;
		collectionType?: CollectionType;
		updateRouteState?: boolean;
	}) => void;

	@Routing.State activeRoute: Route;

	@User.Action saveOnboardingStatus: (newOnboardingStatus: OnboardingStatus) => void;
	@User.Action saveSettings: (settings: Partial<UserSettings>) => Promise<void>;
	@User.Getter isLoggedIn: boolean;

	get buttonRowClasses(): string[] {
		return ['call-to-action__btn-row', !this.showFeatureButton ? 'call-to-action__btn-row--single-btn' : ''];
	}

	get commonEventOptions(): Partial<TrackingProviderPropertiesInterface> {
		return {
			property: `${this.activeRoute.name}.global.${this.ctaPosition}`,
			label: this.$t('WEBAPP_GLOBAL_CTA'),
		} as TrackingProviderPropertiesInterface;
	}

	get ctaPosition() {
		return this.showFeatureButton ? 'TOP' : 'BOTTOM';
	}

	get providerList(): Package[] {
		return this.allProviders(false).slice(0, 9);
	}

	getProviderIconOpacity(index: number): string {
		function getOpacity(val = 4): string {
			return 80 - 12 * val + '%';
		}

		if (index < 3) {
			return getOpacity(index);
		}

		return getOpacity();
	}

	get providerUrls() {
		const providersByShortName = this.providersByShortName;
		const genresByShortName = this.genresByShortName;
		const subgenresList = this.subgenresList;
		return Object.fromEntries(
			this.providerList.map(({ shortName }) => [
				shortName,
				calculateUrl(
					this.$router,
					undefined,
					CollectionType.POPULAR,
					{ providers: [shortName] },
					providersByShortName,
					genresByShortName,
					subgenresList
				),
			])
		) as Record<string, string>;
	}

	visibilityChanged(isVisible: boolean) {
		if (!this.ctaShownOnce && isVisible) {
			this.ctaShownOnce = true;

			TrackingHelper.trackEvent(
				DISCOVERY_INVITATION_EVENT_CATEGORY,
				{
					...this.commonEventOptions,
					action: 'invitation_seen',
					nonInteraction: true,
				} as TrackingProviderPropertiesInterface,
				[TrackingHelper.getPageTypeContext()]
			);
		}
	}

	async discoverClickHandler() {
		const collectionTypes = [CollectionType.POPULAR, CollectionType.NEW];

		collectionTypes.forEach(collectionType => {
			this.setFilterValue({
				filterName: 'providers',
				filterValue: [],
				collectionType,
				updateRouteState: false,
			});
		});

		TrackingHelper.trackEvent(DISCOVERY_INVITATION_EVENT_CATEGORY, {
			action: 'invitation_clicked',
			property: 'bottom',
		});

		await this.saveSettings({
			taste_survey_type: TasteSurveyType.GLOBAL_CLICKED_CTA,
			providers: this.preselectedProviders,
		});

		this.saveOnboardingStatus(this.isLoggedIn ? OnboardingStatus.SIGNED_UP : OnboardingStatus.GLOBAL_CLICKED_CTA);

		this.$emit('click');
	}

	featuresClickHandler() {
		const elementClassToScroll = {
			mobile: '.feature-showcase',
			desktop: '.features-container',
		};

		const selector = window.innerWidth <= 1100 ? elementClassToScroll.mobile : elementClassToScroll.desktop;

		TrackingHelper.trackEvent('userinteraction', {
			action: 'show_features_clicked',
		});

		(document.querySelector(selector) as HTMLElement).scrollIntoView({
			behavior: 'smooth',
		});
	}
}
