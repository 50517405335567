
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import HiddenHorizontalScrollbar from '@/components/HiddenHorizontalScrollbar.vue';
import PictureComp from '@/components/picture/PictureComp.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import PosterGraphql from '@/components/poster/PosterGraphql.vue';
import TitleDetailLink from '@/components/nav/links/TitleDetailLink.vue';
import { captureMessageForSentry } from '@/helpers/sentry-helper';

import {
	GetGlobalProviderTitlesDocument,
	GetGlobalProviderTitlesQueryVariables,
} from '@/pages/graphql/queries/GetGlobalProviderTitles.query';

import { PopularTitlesEdge } from '@/@types/graphql-types';
import { CollectionType } from '@/enums/collection-type';

import { calculateUrl } from '@/helpers/state-helper';
import { SnowplowContext, SnowplowModuleContext } from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { Genre, Subgenre } from '@/constants/types';
import Provider from '@/interfaces/provider';
import { namespace } from 'vuex-class';
import { GlobalModuleName } from '@/helpers/composables/useGlobalTitles';
import { ImpressionTrackingEvents } from '@/enums/events';
import { defaultObservableOptions } from '@/mixins/types/impressionTracking';

const Constant = namespace('constant');

@Component({
	name: 'GlobalProviderSpotlight',
	components: {
		ProviderIcon,
		HiddenHorizontalScrollbar,
		PosterGraphql,
		PictureComp,
		TitleDetailLink,
	},
	apollo: {
		globalProviderSpotlightTitles: {
			query: GetGlobalProviderTitlesDocument,
			fetchPolicy: 'cache-first',
			variables(): GetGlobalProviderTitlesQueryVariables {
				return this.queryVariables;
			},
			update(data) {
				return data.popularTitles?.edges ?? [];
			},
			errorPolicy: 'all',
			error(error) {
				captureMessageForSentry(
					'[GraphQL Get Global Provider Titles Data]:',
					{ error: error, where: 'Component: GlobalProviderSpotlight' },
					'error'
				);
			},
		},
	},
})
export default class GlobalProviderSpotlight extends Vue {
	globalProviderSpotlightTitles: PopularTitlesEdge[] = [];

	@Prop() country: string;
	@Prop() language: string;
	@Prop() provider: { name: string; packageId: number };
	@Prop({ required: true }) moduleName: GlobalModuleName;

	@Constant.Getter genresByShortName: Record<string, Genre>;
	@Constant.Getter subgenresList: Subgenre[];
	@Constant.Getter providersByShortName: Record<string, Provider>;

	get queryVariables() {
		return {
			country: this.country,
			language: this.language,
			filter: {
				packages: [this.provider.name],
			},
			first: 20,
			sortBy: 'POPULAR',
		};
	}

	get providerName() {
		return this.providersByShortName[this.provider.name].clearName;
	}

	get providerUrl() {
		return calculateUrl(
			this.$router,
			undefined,
			CollectionType.POPULAR,
			{
				providers: [this.provider.name],
			},
			this.providersByShortName,
			this.genresByShortName,
			this.subgenresList
		);
	}

	get backdropImage() {
		if (!this.globalProviderSpotlightTitles.length) {
			return '';
		}
		return this.globalProviderSpotlightTitles[0].node.content.backdrops[0]?.backdropUrl || '';
	}

	get moduleContext() {
		return new SnowplowModuleContext('', -1, this.moduleName, '');
	}

	handlePosterClick(title: any, index: number, contexts: SnowplowContext[] = []) {
		const titleContext = TrackingHelper.getTitleContextGraphql(
			title.node.objectId,
			title.node.objectType,
			title?.node?.content?.seasonNumber
		);

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'title_clicked',
				label: 'featured',
				property: this.providerName,
				value: index,
			},
			[...contexts, titleContext, this.moduleContext]
		);
	}

	handleSeeAllClick() {
		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'content_click',
				label: this.providerName,
			},
			[this.moduleContext]
		);
	}

	get observeVisibilityOptions() {
		return {
			...defaultObservableOptions,
			callback: (isVisible: boolean) => {
				if (!isVisible) return;

				TrackingHelper.trackEvent(
					ImpressionTrackingEvents.MODULE,
					{
						action: 'impression',
						nonInteraction: true,
					},
					[this.moduleContext]
				);
			},
		};
	}
}
