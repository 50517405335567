import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetGlobalLargeSpotlightTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	first: Types.Scalars['Int']['input'];
	language: Types.Scalars['Language']['input'];
}>;

export type GetGlobalLargeSpotlightTitlesQuery = {
	__typename?: 'Query';
	popularTitles: {
		__typename?: 'PopularTitlesConnection';
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							fullPath: string;
							posterUrl?: string | null;
							runtime?: number | null;
							shortDescription?: string | null;
							title: string;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
							scoring: { __typename?: 'Scoring'; imdbScore?: number | null; imdbVotes?: number | null };
						};
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
						} | null;
				  }
				| {
						__typename?: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							fullPath: string;
							posterUrl?: string | null;
							runtime?: number | null;
							shortDescription?: string | null;
							title: string;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
							externalIds: { __typename?: 'ExternalIds'; imdbId?: string | null };
							scoring: { __typename?: 'Scoring'; imdbScore?: number | null; imdbVotes?: number | null };
						};
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
						} | null;
				  };
		}> | null;
	};
};

export const GetGlobalLargeSpotlightTitlesDocument = gql`
	query GetGlobalLargeSpotlightTitles($country: Country!, $first: Int!, $language: Language!) {
		popularTitles(country: $country, first: $first) {
			edges {
				node {
					id
					objectId
					objectType
					content(country: $country, language: $language) {
						backdrops {
							backdropUrl
						}
						externalIds {
							imdbId
						}
						fullPath
						posterUrl
						runtime
						scoring {
							imdbScore
							imdbVotes
						}
						shortDescription
						title
					}
					watchNowOffer(country: $country, platform: WEB) {
						id
						standardWebURL
						preAffiliatedStandardWebURL
					}
				}
			}
		}
	}
`;
