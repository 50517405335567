
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import PictureComp from '@/components/picture/PictureComp.vue';
import ProviderIcon from '@/components/picture/ProviderIcon.vue';
import BasicButton from '@/ui-components/BasicButton.vue';

import { CollectionType } from '@/enums/collection-type';
import { OnboardingStatus } from '@/enums/onboarding-status';

import { calculateUrl } from '@/helpers/state-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import { Package } from '@/@types/graphql-types';
import type { Genre, Subgenre } from '@/constants/types';
import { FilterName } from '@/stores/filter.store';
import { TasteSurveyType, UserSettings } from '@/stores/user.store';

const Constant = namespace('constant');
const Filter = namespace('filter');
const User = namespace('user');
const Routing = namespace('routing');

const DISCOVERY_INVITATION_EVENT_CATEGORY = 'discovery';

@Component({
	name: 'GlobalHero',
	components: {
		BasicButton,
		ProviderIcon,
		PictureComp,
	},
	serverCacheKey: ({ country, language }) => `global-hero-${country}-${language}`,
})
export default class GlobalHero extends Vue {
	@Prop() country: string;
	@Prop() language: string;

	@Constant.Getter genresByShortName: Record<string, Genre>;
	@Constant.Getter subgenresList: Subgenre[];
	@Constant.Getter allProviders: (filterByProviders: boolean) => Package[];
	@Constant.Getter providersByShortName: Record<string, Package>;
	@Constant.Getter preselectedProviders: string[];

	@Filter.Action setFilterValue: (payload: {
		filterName: FilterName;
		filterValue: any;
		collectionType?: CollectionType;
		updateRouteState?: boolean;
	}) => void;

	@User.Action saveSettings: (settings: Partial<UserSettings>) => Promise<void>;
	@User.Action saveOnboardingStatus: (newOnboardingStatus: OnboardingStatus) => void;
	@User.Getter isLoggedIn: boolean;

	@Routing.Action fetchUrlMetadata: (url: string) => Promise<void>;

	get ASSETS_DIR(): string {
		return `/${ASSETS_DIR}`;
	}

	get heroImage() {
		return `${ASSETS_DIR}/img/home/global-home-bg-comp.png`;
	}

	get providerList() {
		return [...this.allProviders(false)].slice(0, 13);
	}

	get providerListMobile(): Package[] {
		return this.providerList.slice(0, 4);
	}

	get providerUrls() {
		const providersByShortName = this.providersByShortName;
		const genresByShortName = this.genresByShortName;
		const subgenresList = this.subgenresList;

		return Object.fromEntries(
			this.providerList.map(({ shortName }) => [
				shortName,
				calculateUrl(
					this.$router,
					undefined,
					CollectionType.POPULAR,
					{ providers: [shortName] },
					providersByShortName,
					genresByShortName,
					subgenresList
				),
			])
		);
	}

	async discoverClickHandler() {
		const collectionTypes = [CollectionType.POPULAR, CollectionType.NEW];

		collectionTypes.forEach(collectionType => {
			this.setFilterValue({
				filterName: 'providers',
				filterValue: [],
				collectionType,
				updateRouteState: false,
			});
		});

		TrackingHelper.trackEvent(DISCOVERY_INVITATION_EVENT_CATEGORY, {
			action: 'invitation_clicked',
			property: 'top',
		});

		await this.saveSettings({
			taste_survey_type: TasteSurveyType.GLOBAL_CLICKED_CTA,
			providers: this.preselectedProviders,
		});

		this.saveOnboardingStatus(this.isLoggedIn ? OnboardingStatus.SIGNED_UP : OnboardingStatus.GLOBAL_CLICKED_CTA);

		await this.fetchUrlMetadata(this.$route.path);

		this.$emit('click');
	}

	featuresClickHandler() {
		TrackingHelper.trackEvent('userinteraction', {
			action: 'show_features_clicked',
		});

		let element = document.getElementById('features');
		let headerOffset = 110;
		let elementPosition = element?.getBoundingClientRect().top || 0;
		let offsetPosition = elementPosition + window.scrollY - headerOffset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth',
		});
	}

	handleProviderClick(providerName: string) {
		TrackingHelper.trackEvent('userinteraction', {
			action: 'provider_clicked',
			property: providerName,
		});
	}

	handleSeeAllClick() {
		TrackingHelper.trackEvent('userinteraction', {
			action: 'provider_select_all_clicked',
		});
	}
}
