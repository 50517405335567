import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetGlobalProviderTitlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	filter?: Types.InputMaybe<Types.TitleFilter>;
	first: Types.Scalars['Int']['input'];
	sortBy: Types.PopularTitlesSorting;
	language: Types.Scalars['Language']['input'];
}>;

export type GetGlobalProviderTitlesQuery = {
	__typename?: 'Query';
	popularTitles: {
		__typename?: 'PopularTitlesConnection';
		edges?: Array<{
			__typename?: 'PopularTitlesEdge';
			node:
				| {
						__typename?: 'Movie';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'MovieContent';
							fullPath: string;
							posterUrl?: string | null;
							shortDescription?: string | null;
							title: string;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						};
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
						} | null;
				  }
				| {
						__typename?: 'Show';
						id: string;
						objectId: number;
						objectType: Types.ObjectType;
						content: {
							__typename?: 'ShowContent';
							fullPath: string;
							posterUrl?: string | null;
							shortDescription?: string | null;
							title: string;
							backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
						};
						watchNowOffer?: {
							__typename?: 'Offer';
							id: string;
							standardWebURL?: string | null;
							preAffiliatedStandardWebURL?: string | null;
						} | null;
				  };
		}> | null;
	};
};

export const GetGlobalProviderTitlesDocument = gql`
	query GetGlobalProviderTitles(
		$country: Country!
		$filter: TitleFilter
		$first: Int!
		$sortBy: PopularTitlesSorting!
		$language: Language!
	) {
		popularTitles(country: $country, filter: $filter, first: $first, sortBy: $sortBy) {
			edges {
				node {
					id
					objectId
					objectType
					content(country: $country, language: $language) {
						backdrops {
							backdropUrl
						}
						fullPath
						posterUrl
						shortDescription
						title
					}
					watchNowOffer(country: $country, platform: WEB) {
						id
						standardWebURL
						preAffiliatedStandardWebURL
					}
				}
			}
		}
	}
`;
