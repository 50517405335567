export type ObservableOptions = {
	active: boolean;
	intersection: IntersectionOptions;
	once: boolean;
	throttle: number;
	callback: (isVisible: boolean) => void;
};

export type IntersectionOptions = {
	root: any;
	rootMargin: string;
	threshold: number;
};

export const defaultObservableOptions: ObservableOptions = {
	active: false,
	intersection: {
		root: null,
		rootMargin: '0px 0px 0px 0px',
		threshold: 0.6, // 60% visibility of each element
	},
	once: true,
	throttle: 300,
	callback: () => {},
};
